<script setup lang="ts">
const { $auth } = useNuxtApp();
const isAuthed = ref(false);
$auth.isAuthed().then((authed) => (isAuthed.value = authed));
</script>
<template>
  <header class="h-8 w-full flex justify-end">
    <div class="flex items-center gap-6">
      <SelectLocale />
      <ButtonUserAvatar v-if="isAuthed" />
    </div>
  </header>
</template>
